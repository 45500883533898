import React from "react"
import { useEffect } from "react"
import styles from "../Datenschutz/Datenschutz.module.scss"
import content from "../../content/content.json"

const Datenschutz = () => {

	useEffect(() => {
		setTimeout(function () {
			window.scrollTo(0, 0)
        },250);
	})

    return (
        <section className={styles.Datenschutz}>
            <article className={styles.Datenschutz__Copy}>
                <h1>{content.pages.datenschutz.headline}</h1>
                {content.pages.datenschutz.list.map((entry, i) => 
                    <p dangerouslySetInnerHTML={{ __html: entry }}></p>
                )}
            </article>
        </section>
    );
}

export default Datenschutz;