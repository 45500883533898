import React from "react"
import Layout from "../layouts/Base"
import Datenschutz from "../components/Datenschutz"
import "../style/main.scss"

export default function Home() {

	return (
		<Layout>
			<Datenschutz />
		</Layout>
	)
}
